import { request, noTimeOutReq } from '@/utils/request.js'

//获取操作日志列表
export function getOperationLogListApi (pageIndex, pageSize, data) {
  return request({
    url: `/common/event/http/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data: data
  })
}

