<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="queryParam" label-width="60px">
        <el-form-item label>
          <el-input
            v-model="queryParam.query"
            placeholder="请输入操作人编码"
            @input="getOperationLogList"
          ></el-input>
        </el-form-item>
        <el-button
          v-throttle
          type="primary"
          icon="el-icon-search"
          @click="search"
          >搜索</el-button
        >
        <el-button
          v-throttle
          type="primary"
          icon="el-icon-refresh-right"
          @click="refresh"
          >刷新</el-button
        >
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="multipleTable"
        :data="operationLogList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
      >
        <el-table-column
          label="操作时间"
          min-width="50"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.gmtCreate != null">{{
              scope.row.gmtCreate | dateFormat
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="httpMethod"
          label="操作"
          min-width="25"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createByName"
          label="操作人"
          min-width="40"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createByCode"
          label="操作人编码"
          min-width="60"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ip"
          label="请求ip"
          min-width="40"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="url"
          label="url"
          min-width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="jsonRecordPreview"
          label="记录日志"
          min-width="100"
          height="48"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 12, 15]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { getOperationLogListApi } from '@/api/system/operationLog.js'
import { isEmpty } from '@/utils/util'
export default {
  name: 'operationLog',
  data() {
    return {
      queryParam: {
        query: '',
      },
      loading: false,
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      operationLogList: [],
    }
  },
  mounted() {
    this.init()
  },
  filters: {
    //时间过滤
    dateFormat(val) {
      const dt = new Date(val)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      //padStart(2,'0') 意思是不足两位自动补0
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
  },
  methods: {
    init() {
      this.getOperationLogList()
    },
    getOperationLogList() {
      this.loading = true
      let fd = new FormData()
      fd.append('query', this.queryParam.query)
      getOperationLogListApi(this.page.pageIndex, this.page.pageSize, fd)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.operationLogList = []
            this.operationLogList = res.data
            this.operationLogList.forEach((item) => {
              item.jsonRecordPreview = item.jsonRecord.slice(0, 4000)
            })
            this.page.totalSize = 0
            this.page.totalSize = res.total
          } else {
            if (res.code != 500) {
              this.msgWarning(res.msg)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getOperationLogList()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getOperationLogList()
    },
    search() {
      this.getOperationLogList()
    },
    refresh() {
      this.queryParam.query = ''
      this.init()
    },
  },
  watch: {},
}
</script>
<style></style>
